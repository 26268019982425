import request from "@/utils/request";
const url = "https://huanxinxiang.ai-me.cn";
// const url = "https://test-huanxinxiang.ai-me.cn";
const appName = "huanxinxiang-laikeyou";

// 发送短信
export const code = (data) => request({
  url: '/huanxinxiang-service/v1/code/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  ipBaseURL: url,
  data,
});

// 支付宝签约
export const codeDlipayPay = (data) => request({
  url: '/huanxinxiang-service/v1/alipay-sms-pay/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  ipBaseURL: url,
  data,
});